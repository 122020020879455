import { useContext } from "react";
import Footer from "../footer/footer";
import Menu from "../menu/menu";
import { WebsiteContext } from "../data/website.context";
import { ThemeRouter } from "@soltivo/theme-library";
import pageComponents from "../pages";

const Routes = () => {
  const { website } = useContext(WebsiteContext);

  return (
    <div id="theme">
      <Menu website={website} />
      <ThemeRouter pageComponents={pageComponents} website={website} />
      <Footer website={website} />
    </div>
  );
};

export default Routes;
