import { Anchor, Logo, useSiteMap } from "@soltivo/theme-library";
import { useRouteMatch } from "react-router-dom";

interface FooterProps {
  website: WebsiteData;
}

const Footer: React.FC<FooterProps> = ({ website }) => {
  const isBooking = useRouteMatch({
    path: "/booking",
    exact: true,
    sensitive: false,
  });
  const { flatItems } = useSiteMap({ website });

  if (isBooking?.isExact) return null;
  return (
    <div className="footer-one" data-class="footer">
      <div className="container">
        <div className="footer-one__header">
          <div className="footer-one__header__logo">
            {website.footer.data.logo?.visible ? (
              <h2>
                <Logo
                  imgProps={{ style: { maxHeight: "60px", maxWidth: "100px", minHeight: "45px" } }}
                  logoKey={website.footer.data.logo?.logoKey}
                  website={website}
                />
              </h2>
            ) : null}
          </div>
          <div className="footer-one__header__social">
            <div className="social-icons -border">
              {website.footer.data.socialMedia?.visible ? (
                <ul>
                  {website?.contact.socialMedia.map((sm) => {
                    return (
                      <li key={sm.id}>
                        <a
                          key={sm.id}
                          href={sm.href}
                          target="_blank"
                          rel="noreferrer"
                          title={`${sm.name}`}
                          dangerouslySetInnerHTML={{ __html: sm.svgHTML }}
                        />
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>
        </div>
        <div className="footer-one__body">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              {website.footer.data.contactInformation?.visible ? (
                <div className="footer__section -info">
                  <h5 className="footer-title">{website.footer.data.contactInformation?.title}</h5>

                  {Object.keys(website.contact.information).map((key) => {
                    return (
                      <p key={key}>
                        <span>{website.contact.information[key]}</span>
                      </p>
                    );
                  })}
                </div>
              ) : null}
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="footer__section -links">
                <div className="row">
                  {website.footer?.data.siteMap?.visible ? (
                    <div className="col-12 col-sm-6">
                      <h5 className="footer-title">{website.footer?.data.siteMap?.title}</h5>
                      <ul>
                        {flatItems.map((item, index) => {
                          return (
                            <li key={index}>
                              <Anchor website={website} {...item}>
                                {item.value}
                              </Anchor>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                  {/* <div className="col-12 col-sm-6">
                    <h5 className="footer-title">Account</h5>
                    <ul>
                      <li>
                        <a href="#">My account</a>
                      </li>
                      <li>
                        <a href="wishlist.html">Wishlist</a>
                      </li>
                      <li>
                        <a href="cart">Cart</a>
                      </li>
                      <li>
                        <a href="fullwidth-4col">Shop</a>
                      </li>
                      <li>
                        <a href="checkout">Checkout</a>
                      </li>
                    </ul>{" "}
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              {website.footer?.data.text?.visible ? (
                <div className="footer__section -info">
                  <h5 className="footer-title">{website.footer?.data.text?.title}</h5>

                  <p>{website.footer?.data.text?.value}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="footer-one__footer">
        <div className="container">
          <div className="footer-one__footer__wrapper">
            <p className="mx-auto">{`${website?.footer.data.copyright?.value}`}</p>
            {/* <ul>
              <li>
                <a href="contact.html">Privacy Policy</a>
              </li>
              <li>
                <a href="contact.html">Terms &amp; Condition</a>
              </li>
              <li>
                <a href="contact.html">Site map</a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
