import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";
import "./breadcrumb.styles.scss";
import { ReactComponent as BgSVG } from "../../assets/images/common/Breadcrumb/bg.svg";
import { matchPath, useLocation } from "react-router-dom";

const Breadcrumb: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);
  const location = useLocation<any>();
  const found = website.routes.list.find((item) =>
    matchPath(location.pathname, {
      exact: true,
      path: item.path,
    })
  );

  const shop = location?.state?.formation;
  const paths = location?.pathname.split("/");
  paths.shift();

  return (
    <section id={section.targetId} className="container__max">
      <div className="breadcrumb breadcrumb_custom">
        <BgSVG />
        <div className="container">
          <h2>{found?.pageName || shop.title || ""}</h2>
          <ul>
            <li>Home</li>
            {paths.length
              ? paths.map((path, index) => (
                  <li key={index} className={`${index === paths.length - 1 ? "active" : ""}`}>
                    {path}
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;
