import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";
import { Video, Anchor } from "@soltivo/theme-library";
import { BookingUrl } from "@soltivo/theme-library/core/utils";

const IntroductionVideo: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);

  return (
    <section id={section.targetId} className="container__max">
      <div className="slider -style-1 slider-arrow-middle background__video">
        <div
          className="slider__carousel"
          // jquery issue
          // data-class="group"
          // data-path={`${section.name}/carousel`}
        >
          {section?.data.carousel.map(
            (
              item: {
                id: string;
                sub_title: string;
                title: string;
                videoSrc: string;
                playing: boolean;
                loop: boolean;
                thumbnail: string;
                description: string;
                linkHref: string;
                linkValue: string;
              },
              index: number
            ) => (
              <div
                key={index}
                data-class="object"
                data-path={`${section.name}/carousel/id:${item.id}`}
                className={`slider__carousel__item slider-${index}`}>
                <div className="container">
                  <div className="slider-background">
                    <Video
                      key={index}
                      playing={item.playing}
                      loop={item.loop}
                      controls={false}
                      muted={true}
                      volume={0}
                      description={item.description}
                      src={item.videoSrc}
                      title={item.title}
                      light={false}
                      thumbnail={item.thumbnail}
                      reactPlayerProps={{
                        config: {
                          vimeo: {
                            playerOptions: {
                              controls: "false",
                            },
                          },
                        },
                        style: {
                          pointerEvents: "none",
                        },
                      }}
                      options={{
                        smFluidBg: true,
                      }}
                    />
                  </div>
                  <div className="slider-content">
                    <h5 className="slider-content__subtitle" data-animation-in="fadeInUp" data-animation-delay="0.1">
                      {item.sub_title}
                    </h5>
                    <h1 className="slider-content__title" data-animation-in="fadeInUp" data-animation-delay="0.2">
                      {item.title}
                    </h1>
                    <div data-animation-in="fadeInUp" data-animation-out="fadeInDown" data-animation-delay="0.4">
                      <Anchor
                        rel="noreferrer"
                        target={"_blank"}
                        title={item.description}
                        value={item.linkValue}
                        website={website}
                        href={BookingUrl(website)}
                        className="btn -red">
                        {item.linkValue}
                      </Anchor>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default IntroductionVideo;
