import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";
import placeholderImage from "../../helpers/assets/images/image_placeholder.png";
import { ReactComponent as BgSVG } from "../../assets/images/introduction/IntroductionThree/bg.svg";
import { Anchor } from "@soltivo/theme-library";

export type FormationList = Array<WebsitePlugin<"booking/service"> & { index: number }>;

const Services: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);

  const services: WebsiteGroup<WebsitePlugin<"booking/service">> = section?.data.services;

  return (
    <section id={section.targetId} className="container__max">
      <div className="shop">
        <div className="container">
          {services.map((service, index) => {
            return (
              <div className={`services__item ${index % 2 ? "-reverse" : ""}`} key={index}>
                <div className="row">
                  {!(index % 2) ? (
                    <div className="col-12 col-md-6">
                      <div className="services__item__image">
                        <div className="services__item__image__background">
                          <BgSVG />
                        </div>
                        <div className="services__item__image__detail">
                          <div className="image__item">
                            <div className="wrapper">
                              <img data-depth="0.3" src={service.image || placeholderImage} alt="image" />
                            </div>
                          </div>
                          {/* <div className="image__item">
                            <div className="wrapper">
                              <img
                                data-class="image"
                                data-path={`services/services/${index}/images/${1}`}
                                data-depth="0.2"
                                data-invert-x="true"
                                data-invert-y="true"
                                src={service.images[1] || placeholderImage}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-12 col-md-6">
                    <div className="services__item__content">
                      <div className="services__item__order">
                        <h3>0{index + 1}.</h3>
                      </div>
                      <span data-class="plugin" data-plugin="booking/service" data-path={`services/services/${index}`}>
                        <h2 className="services__item__title">{service.title}</h2>
                        <p className={`services__item__description ${!(index % 2) ? "reverse" : ""}`}>
                          {service.description}
                        </p>
                      </span>
                      <ul>
                        {/* {service.checkbox_list.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <i
                              data-class="object"
                              data-path={`services/services/${index}/checkbox_list/${itemIndex}`}
                              className="fas fa-check"
                            />
                            <span data-class="text" data-path={`services/services/${index}/checkbox_list/${itemIndex}`}>
                              {item}
                            </span>
                          </li>
                        ))} */}
                      </ul>
                      <Anchor
                        website={website}
                        {...section.data.link}
                        data-class="link"
                        data-path={`services/link`}
                        className="btn -white">
                        {section.data.link.value}
                      </Anchor>
                    </div>
                  </div>

                  {index % 2 ? (
                    <div className="col-12 col-md-6">
                      <div className="services__item__image">
                        <div className="services__item__image__background">
                          <BgSVG />
                        </div>
                        <div className="services__item__image__detail">
                          <div className="image__item">
                            <div className="wrapper">
                              <img data-depth="0.3" src={service.image || placeholderImage} alt="image" />
                            </div>
                          </div>
                          {/* <div className="image__item">
                            <div className="wrapper">
                              <img
                                data-class="image"
                                data-path={`services/services/${index}/images/${1}`}
                                data-depth="0.2"
                                data-invert-x="true"
                                data-invert-y="true"
                                src={service.images[1] || placeholderImage}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
