import { useContext } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { WebsiteContext } from "../../data/website.context";
import { FormationList } from "../formations/formations";
import placeholderImage from "../../helpers/assets/images/image_placeholder.png";
import { ReactComponent as BgSVG } from "../../assets/images/introduction/IntroductionThree/bg.svg";

const Formation: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);
  const location = useLocation<any>();

  const formation: FormationList[0] | undefined = location?.state?.formation;
  const formationIndex: number | undefined = location?.state?.index;

  if (!formation || !formationIndex) {
    return <Redirect to={website.routes.list.find((route) => route.pageName === "formations")?.path || "/"} />;
  }

  return (
    <section id={section.targetId} className="container__max">
      <div className="shop">
        <div className="container">
          <div className="services__item">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="services__item__image">
                  <div className="services__item__image__background">
                    <BgSVG />
                  </div>
                  <div className="services__item__image__detail">
                    <div className="image__item">
                      <div className="wrapper">
                        <img
                          data-depth="0.3"
                          src={formation.imagePrimary || placeholderImage}
                          alt={`${formation.title} image`}
                        />
                      </div>
                    </div>
                    <div className="image__item">
                      <div className="wrapper">
                        <img
                          data-depth="0.2"
                          data-invert-x="true"
                          data-invert-y="true"
                          src={formation.imageSecondary || placeholderImage}
                          alt={`${formation.title} image`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="services__item__content">
                  <div className="services__item__order">
                    <h3>{formationIndex < 10 ? `0${formationIndex + 1}` : formationIndex + 1}.</h3>
                  </div>
                  <h2 className="services__item__title">{formation.title}</h2>
                  <p className="services__item__description">{formation.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Formation;
