import { Anchor } from "@soltivo/theme-library";
import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";

const FollowUs: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);

  //
  return (
    <section id={section.targetId} className="container__max">
      <div className="instagram-one">
        <div
          className="instagram-one__background"
          data-class="background-image"
          data-path="follow_us/background"
          style={{
            width: "100%",
            height: "400px",
            backgroundImage: `url(${section?.data.background.src})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "overlay",
            backgroundRepeat: "no-repeat",
          }}></div>
        <span className="instagram-one__content">
          {website.contact.socialMedia.map((item) => {
            return (
              <a
                key={item.id}
                href={item.href}
                dangerouslySetInnerHTML={{ __html: item.svgHTML }}
                target="_blank"
                rel="noreferrer"
              />
            );
          })}
          <h5 data-class="text" data-path="follow_us/title">
            {section?.data.title.value}
          </h5>
          <Anchor website={website} {...section.data.hashtag} data-class="link" data-path="follow_us/hashtag">
            {section?.data.hashtag.value}
          </Anchor>
        </span>
      </div>
    </section>
  );
};

export default FollowUs;
