// import NotFound from "./404/not.found";
import Contact from "./contact/contact";
import Formation from "./formation/formation";
import Formations from "./formations/formations";
import Home from "./home/home";
import Services from "./services/services";

const pageComponents: PageComponents = [
  {
    name: "home",
    render: Home,
  },
  {
    name: "services",
    render: Services,
  },
  {
    name: "formations",
    render: Formations,
  },
  {
    name: "formation",
    render: Formation,
  },
  {
    name: "contact",
    render: Contact,
  },

  // {
  //     pageId: 6,
  //     render: NotFound,
  // },
];

export default pageComponents;
