import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";
import { ReactComponent as Bg1 } from "../../assets/images/introduction/IntroductionOne/bg1.svg";
import { ReactComponent as Bg2 } from "../../assets/images/introduction/IntroductionOne/bg2.svg";
import { ReactComponent as ContentDeco } from "../../assets/images/introduction/IntroductionOne/content-deco.svg";
import { Anchor } from "@soltivo/theme-library";

const About: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);

  return (
    <section id={section.targetId} className="container__max">
      <div className="introduction-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="introduction-one-image">
                <div className="introduction-one-image__detail">
                  <img
                    data-class="image"
                    data-path={`${section.name}/top_image`}
                    width={390}
                    height={455}
                    src={section?.data.top_image.src}
                    alt={section?.data.top_image.alt}
                  />
                  <img
                    data-class="image"
                    data-path={`${section.name}/bottom_image`}
                    width={370}
                    height={440}
                    src={section?.data.bottom_image.src}
                    alt={section?.data.top_image.alt}
                  />
                </div>
                <div className="introduction-one-image__background">
                  <div className="background__item">
                    <div className="wrapper">
                      <Bg1 data-depth="0.5" />
                    </div>
                  </div>
                  <div className="background__item">
                    <div className="wrapper">
                      <Bg2 data-depth="0.3" data-invert-x="" data-invert-y="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="introduction-one-content">
                <h5>
                  <span data-class="text" data-path={`${section.name}/sub_title/0`}>
                    {section?.data.sub_title[0].value}
                  </span>{" "}
                  <span data-class="text" data-path={`${section.name}/sub_title/1`}>
                    {section?.data.sub_title[1].value}
                  </span>
                </h5>
                <div style={{ marginBottom: "1.875em" }} className="section-title">
                  <h2 data-class="text" data-path={`${section.name}/title`}>
                    {section?.data.title.value}
                  </h2>
                  <ContentDeco />
                </div>
                <p data-class="text" data-path={`${section.name}/description`}>
                  {section?.data.description.value}
                </p>
                <Anchor
                  website={website}
                  className="btn -white"
                  data-path={`${section.name}/link`}
                  data-class="link"
                  {...section.data.link}>
                  {section?.data.link.value}
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
