import { useContext, useEffect } from "react";
import { WebsiteContext } from "../../data/website.context";
import { runHeroSliderAnimation } from "../../assets/js/main.jquery";
import { Anchor } from "@soltivo/theme-library";
import { BookingUrl } from "@soltivo/theme-library/core/utils";

type IntroductionDataType = ReturnType<typeof import("./introduction.data").default>["data"];

const Introduction: SectionComponent<IntroductionDataType> = ({ section }) => {
  const { website } = useContext(WebsiteContext);
  useEffect(() => {
    if (theme.window?.document) {
      runHeroSliderAnimation();
    }
  }, []);

  return (
    <section id={section.targetId} className="container__max">
      <div className="slider -style-1 slider-arrow-middle">
        <div
          className="slider__carousel"
          //Jquery issue
          // data-class="group"
          // data-path={`${section.name}/carousel`}
        >
          {section.data.carousel.map(
            (
              item: {
                id: string;
                sub_title: string;
                title: string;
                backgroundSrc: string;
                description: string;
                linkValue: string;
              },
              index: number
            ) => (
              <div
                key={index}
                data-class="object"
                data-path={`${section.name}/carousel/id:${item.id}`}
                className={`slider__carousel__item slider-${index}`}>
                <div className="container">
                  <div className="slider-background">
                    <img className="slider-background" src={item.backgroundSrc} alt={item.description} />
                  </div>
                  <div className="slider-content">
                    <h5 className="slider-content__subtitle" data-animation-in="fadeInUp" data-animation-delay="0.1">
                      {item.sub_title}
                    </h5>
                    <h1 className="slider-content__title" data-animation-in="fadeInUp" data-animation-delay="0.2">
                      {item.title}
                    </h1>
                    <div data-animation-in="fadeInUp" data-animation-out="fadeInDown" data-animation-delay="0.4">
                      <Anchor
                        rel="noreferrer"
                        target={"_blank"}
                        title={item.description}
                        value={item.linkValue}
                        website={website}
                        href={BookingUrl(website)}
                        className="btn -red">
                        {item.linkValue}
                      </Anchor>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Introduction;
