import { RenderPage } from "@soltivo/theme-library";
import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";
import sectionComponents from "../../sections";

function Formation() {
  const { website } = useContext(WebsiteContext);
  return <RenderPage sectionComponents={sectionComponents} page={website.page} />;
}

export default Formation;
