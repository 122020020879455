import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import placeholderImage from "../../helpers/assets/images/image_placeholder.png";
import PopupFormation from "../formation/popup.formation";
const formationList = [
  {
    id: "",
    imagePrimary: "url",
    imageSecondary: "url",
    title: "The expert mascaraa",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque ipsa fugiat accusantium reiciendis rem doloribus iste doloremque",
    price: "$35.00",
  },
];

export type FormationList = ReturnType<() => typeof formationList>;

const Formations: SectionComponent = ({ section }) => {
  const history = useHistory();
  const [formationList, setFormationList] = useState<FormationList>([]);
  const [formation, setFormation] = useState<FormationList[0]>();
  const [grid, setGrid] = useState<4 | 1>(4);

  const onFilter: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    let value = e.target.value as "az" | "za";

    if (value === "za") {
      const list = formationList.sort((a, b) => (a.title < b.title ? 1 : -1));
      setFormationList([...list]);
    } else if (value === "az") {
      const list = formationList.sort((a, b) => (a.title > b.title ? 1 : -1));
      setFormationList([...list]);
    }
  };

  const onChangeGrid: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setGrid(grid === 1 ? 4 : 1);
  };

  useEffect(() => {
    if (section) setFormationList(section.data.formations);
  }, [section]);

  const onOpen = (formation: FormationList[0], index: number) => {
    // setFormation(formation);
    history.push(`/formations/${formation.title.replace(/ /g, "-".toLowerCase())}`, {
      formation,
      // +1 because some people don't like to count from 0.
      index: index + 1,
    });
  };

  const onClose = () => {
    setFormation(undefined);
  };

  return (
    <section id={section.targetId}>
      <PopupFormation formation={formation} onClose={onClose} />
      <div className="container__max">
        <div className="shop">
          <div className="container">
            <div className="shop-header">
              <div className="shop-header__view" data-class="object" data-path={`${section.name}/view`}>
                <div className="shop-header__view__icon">
                  <button className={grid === 4 ? "active" : ""} onClick={onChangeGrid}>
                    <i className="fas fa-th" />
                  </button>
                  <button className={grid === 1 ? "active" : ""} onClick={onChangeGrid}>
                    <i className="fas fa-bars" />
                  </button>
                </div>
                <h5 className="shop-header__page">{grid === 1 ? section?.data.view.small : section?.data.view.big}</h5>
              </div>
              <select className="customed-select" name="filter" onChange={onFilter}>
                <option value="az">A to Z</option>
                <option value="za">Z to A</option>
              </select>
            </div>
            <div className="shop-products">
              {/* Grid columns */}
              <div
                data-class="group"
                data-path={`${section.name}/formations`}
                className="shop-products__gird"
                style={{ display: grid === 4 ? "unset" : "none" }}>
                <div className="row mx-n1 mx-lg-n3">
                  {formationList.map((formation, index) => (
                    <div
                      className="col-6 col-md-4 col-lg-3 px-1 px-lg-3"
                      key={index}
                      onClick={() => onOpen(formation, index)}>
                      <div
                        className="product"
                        data-class="object"
                        data-path={`${section.name}/formations/id:${formation.id}`}>
                        <div className="product-thumb">
                          <span className="product-thumb__image">
                            <img src={formation.imagePrimary || placeholderImage} alt={`${formation.title} image`} />
                            <img src={formation.imageSecondary || placeholderImage} alt={`${formation.title} image`} />
                          </span>
                        </div>
                        <div className="product-content d-flex align-items-center">
                          <span className="product-name mr-2">{formation.title}</span>

                          <h5 className="product-price--main  ml-auto">{formation.price}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Grid 4 list */}
              <div
                data-class="group"
                data-path={`${section.name}/formations`}
                className="shop-products__list"
                style={{ display: grid === 1 ? "unset" : "none" }}>
                {formationList.map((formation, index) => (
                  <div className="row mx-n1 mx-lg-n3" key={index} onClick={() => onOpen(formation, index)}>
                    <div className="col-12 px-1 px-lg-3">
                      <div
                        className="product-list"
                        data-class="object"
                        data-path={`${section.name}/formations/id:${formation.id}`}>
                        <div className="product-list-thumb">
                          <span className="product-list-thumb__image">
                            <img src={formation.imagePrimary || placeholderImage} alt={`${formation.title} image`} />
                            <img src={formation.imageSecondary || placeholderImage} alt={`${formation.title} image`} />
                          </span>
                        </div>
                        <div className="product-list-content">
                          <div className="product-list-content__top d-flex align-items-center">
                            <span className="product-name mr-2">{formation.title}</span>

                            <h5 className="product-price--main ml-auto">{formation.price}</h5>
                          </div>
                          <div className="product-list-content__bottom">
                            <p className="product-description">{formation.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Formations;
