import React, { FC, useContext } from "react";
import "./popup.formation.styles.scss";
import { ReactComponent as BgSVG } from "../../assets/images/introduction/IntroductionThree/bg.svg";
import { FormationList } from "../formations/formations";
import placeholderImage from "../../helpers/assets/images/image_placeholder.png";
import { WebsiteContext } from "../../data/website.context";
import { findSection } from "@soltivo/theme-library";

interface PopupFormationProps {
  formation?: FormationList[0];
  onClose?: () => void;
}

const PopupFormation: FC<PopupFormationProps> = ({ formation, onClose }) => {
  const { website } = useContext(WebsiteContext);
  const section = findSection(website, "formations");
  if (!formation) return null;
  return (
    <div id="popup__formation">
      <div className="container__custom">
        <div className="shop">
          <div className="container">
            <div className="services__item">
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="services__item__image">
                    <div className="services__item__image__background">
                      {/* <img src="assets/images/introduction/IntroductionThree/bg.png" alt="background" /> */}
                      <BgSVG />
                    </div>
                    <div className="services__item__image__detail">
                      <div className="image__item">
                        <div className="wrapper">
                          <img
                            data-depth="0.3"
                            src={formation.image || placeholderImage}
                            alt={`${formation.title} image`}
                          />
                        </div>
                      </div>
                      <div className="image__item">
                        <div className="wrapper">
                          <img
                            data-depth="0.2"
                            data-invert-x="true"
                            data-invert-y="true"
                            src={formation.image_two || placeholderImage}
                            alt={`${formation.title} image`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="services__item__content">
                    <div className="services__item__order">
                      <h3>{formation.index < 10 ? `0${formation.index + 1}` : formation.index + 1}.</h3>
                    </div>
                    <h2 className="services__item__title">{formation.title}</h2>
                    <p className="services__item__description">{formation.description}</p>

                    <h3 className="text-right">{formation.price}</h3>
                    {/* <a data-for="text" data-type="formations/button" className="btn -white" to={"/contactus"}>
                                            {section?.data.button}
                                        </a> */}

                    <span className="close" role="button" onClick={onClose}>
                      {section?.data.button_close || "Close"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupFormation;
