import { Map } from "@soltivo/theme-library";
import { useContext } from "react";
import { WebsiteContext } from "../../data/website.context";

const Contact: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);

  return (
    <section id={section.targetId} className="container__max">
      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h3 data-class="text" data-path={`${section.name}/title`} className="contact-title">
                {section?.data.title.value}
              </h3>

              {Object.keys(website.contact.information).map((key, index) => {
                if (!website.contact.information[key]) {
                  return null;
                }
                return (
                  <div className="contact-info__item" key={index}>
                    <div className="contact-info__item__detail">
                      <h3 data-class="text" data-path={`${section.name}/${key}`}>
                        {section.data[key].value}
                      </h3>
                      <p>{website.contact.information[key]}</p>
                    </div>
                  </div>
                );
              })}

              <div className="contact-info__item">
                <div className="contact-info__item__detail">
                  <h3 data-class="text" data-path={`${section.name}/open_time_label`}>
                    {section.data["open_time_label"].value}
                  </h3>
                  <p data-class="text" data-path={`${section.name}/open_time`}>
                    {section.data["open_time"].value}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              {/* <h3 className="contact-title">Get in touch</h3>
              <div className="contact-form">
                <form>
                  <div className="input-validator">
                    <input type="text" name="name" placeholder="Name" />
                  </div>
                  <div className="input-validator">
                    <input type="text" name="email" placeholder="Email" />
                  </div>
                  <div className="input-validator">
                    <textarea name="message" cols={30} rows={3} placeholder="Message" defaultValue={""} />
                  </div>
                  <a className="btn -dark" href="#">
                    SEND MESSAGE
                  </a>
                </form>
              </div> */}

              <Map
                {...section.data.map}
                iframeProps={{
                  className: "contact-map",
                }}
                dataClass="map"
                dataPath={`${section.name}/map`}
                THEME_GOOGLE_API_KEY={process.env.THEME_GOOGLE_API_KEY as string}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
