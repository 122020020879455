import { Anchor, Logo, useSiteMap } from "@soltivo/theme-library";
import { useEffect } from "react";
import { onOpenDrawer, openSearchBox } from "../assets/js/main.jquery";

interface MenuProps {
  website: WebsiteData;
}

const Menu: React.FC<MenuProps> = ({ website }) => {
  useEffect(() => {
    if (theme.window?.document) {
      setTimeout(() => {
        openSearchBox();
        onOpenDrawer();
      }, 1000);
    }
  }, []);

  const { groups } = useSiteMap({ website });

  return (
    <>
      <div className="header -one">
        {/* <div className="top-nav -style-1">
          <div className="container">
            <div className="top-nav__wrapper">
              <div className="top-nav-social">
                <div className="social-icons -white">
                  <ul>
                    {website.contact.socialMedia.map((sm) => {
                      return (
                        <li key={sm.id}>
                          <a
                            href={sm.href}
                            target="_blank"
                            rel="noreferrer"
                            dangerouslySetInnerHTML={{ __html: sm.svgHTML }}></a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <p className="top-nav-promo">{website.general.nav_description}</p>
            </div>
          </div>
        </div> */}
        <div className="menu -style-1" data-class="menu">
          <div className="container">
            <div className="menu__wrapper">
              {website.menu.data.logo?.visible ? (
                <h2 className="menu-logo">
                  <Logo
                    imgProps={{ style: { maxHeight: "60px", maxWidth: "100px", minHeight: "45px" } }}
                    logoKey={website.menu.data.logo?.logoKey}
                    website={website}
                  />
                </h2>
              ) : null}

              {website.menu.data.siteMap?.visible ? (
                <div className="navigator ml-auto">
                  <ul>
                    {groups.map((group) => {
                      if (group.items.length === 1) {
                        return (
                          <li key={group.id}>
                            <Anchor website={website} {...group.items[0]}>
                              {group.items[0].value || ""}
                            </Anchor>
                          </li>
                        );
                      }

                      return (
                        <li className="relative" key={group.id}>
                          <a href="#">
                            {group.value}
                            <span className="dropable-icon">
                              <i className="fas fa-angle-down"></i>
                            </span>
                          </a>
                          <ul className="dropdown-menu">
                            {group.items.map((item, gIndex) => {
                              return (
                                <li key={gIndex}>
                                  <Anchor website={website} {...item}>
                                    {item.value || ""}
                                  </Anchor>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              <div className="menu-functions ">
                <a className="menu-icon -navbar" href="#">
                  <div className="bar" />
                  <div className="bar" />
                  <div className="bar" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="drawer drawer-right slide"
        id="mobile-menu-drawer"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="drawer-demo-title"
        style={{ display: "none" }}
        aria-hidden="true">
        <div className="drawer-content drawer-content-scrollable" role="document">
          <div className="drawer-body">
            <div className="cart-sidebar">
              <div className="cart-items__wrapper">
                <div className="navigation-sidebar">
                  {website.menu.data.siteMap?.visible ? (
                    <div className="navigator-mobile">
                      <ul>
                        {groups.map((group) => {
                          if (group.items.length === 1) {
                            return (
                              <li key={group.id}>
                                <Anchor website={website} {...group.items[0]}>
                                  {group.items[0].value || ""}
                                </Anchor>
                              </li>
                            );
                          }

                          return (
                            <li className="relative" key={group.id}>
                              <a className="dropdown-menu-controller" href="#">
                                {group.value}
                                <span className="dropable-icon">
                                  <i className="fas fa-angle-down"></i>
                                </span>
                              </a>
                              <ul className="dropdown-menu">
                                {group.items.map((item, gIndex) => {
                                  return (
                                    <li key={gIndex}>
                                      <Anchor website={website} {...item}>
                                        {item.value || ""}
                                      </Anchor>
                                    </li>
                                  );
                                })}
                              </ul>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : null}
                  {/* <div className="search-box">
                    <form>
                      <input type="text" placeholder="What are you looking for?" />
                      <button>
                        <img src="assets/images/header/search-icon.png" alt="Search icon" />
                      </button>
                    </form>
                  </div> */}
                  <div
                    className="navigation-sidebar__footer"
                    style={{
                      zIndex: -1,
                      visibility: "hidden",
                    }}>
                    {/* <select className="customed-select -borderless" name="currency">
                      <option value="usd">USD</option>
                      <option value="vnd">VND</option>
                      <option value="yen">YEN</option>
                    </select>
                    <select className="customed-select -borderless" name="currency">
                      <option value="en">EN</option>
                      <option value="vi">VI</option>
                      <option value="jp">JP</option>
                    </select> */}
                  </div>
                  <div className="social-icons">
                    <ul>
                      {website.contact.socialMedia.map((sm) => {
                        return (
                          <li key={sm.id}>
                            <a
                              href={sm.href}
                              target="_blank"
                              rel="noreferrer"
                              dangerouslySetInnerHTML={{ __html: sm.svgHTML }}></a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
