import { Button } from "@soltivo/theme-library";
import { useEffect } from "react";
import { ReactComponent as ContentDeco } from "../../assets/images/introduction/IntroductionOne/content-deco.svg";
import { slickSlider } from "../../assets/js/main.jquery";
import imagePlaceholder from "../../helpers/assets/images/image_placeholder.png";

const Testimonial: SectionComponent = ({ section }) => {
  const list = section.data.list as {
    id: string;
    image: string;
    name: string;
    from: string;
    stars: number;
    description: string;
  }[];

  useEffect(() => {
    if (theme.window?.document) {
      slickSlider();
    }
  }, [list.length]);

  return (
    <section id={section.targetId} className="container__max">
      <div className="testimonial">
        <div className="section-title -center" style={{ marginBottom: "3.125rem" }}>
          <h5 data-class="text" data-path={`${section.name}/sub_title`}>
            {section.data.sub_title.value}
          </h5>
          <h2 data-class="text" data-path={`${section.name}/title`}>
            {section.data.title.value}
          </h2>
          <ContentDeco />
        </div>

        <div className="container">
          <div className="testimonial-slider">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="slide-nav">
                  <div className="slide-nav__wrapper">
                    {list.map((item, index: number) => (
                      <div
                        data-class="object"
                        data-path={`${section.name}/list/id:${item.id}`}
                        className="slide-nav__item"
                        data-key={index}
                        key={item.id}>
                        <img src={item.image || imagePlaceholder} alt={`${item.name} avatar`} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="slide-for">
                  <div
                    // Jquery won't allow this without breaking on the website builder.
                    // data-class="group"
                    // data-path={`${section.name}/list`}
                    className="slide-for__wrapper">
                    {list.map((item, index) => (
                      <div className="slide-for__item" key={index}>
                        <div className="slide-for__item__header">
                          <div className="quote-icon">
                            <i className="fas fa-quote-right" />
                          </div>
                          <div className="customer__info">
                            <h3>{item.name}</h3>
                            <h5>{item.from}</h5>
                          </div>
                          {/* <div className="rate">
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                            <i className="fas fa-star" />
                          </div> */}
                        </div>
                        <p className="slide-for__item__content">{item.description}</p>
                      </div>
                    ))}
                  </div>
                  {list.length > 1 ? (
                    <div className="testimonial-one__slider-control">
                      <Button
                        {...section.data.buttons[0]}
                        data-class="button"
                        data-path={`${section.name}/buttons/0`}
                        className="prev">
                        <i className="far fa-angle-left"> </i>
                        <span>{section.data.buttons[0].value}</span>
                      </Button>
                      <Button
                        {...section.data.buttons[1]}
                        data-class="button"
                        data-path={`${section.name}/buttons/1`}
                        className="next">
                        <span>{section.data.buttons[1].value}</span>
                        <i className="far fa-angle-right"> </i>
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
