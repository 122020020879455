import { useState } from "react";
import { Video } from "@soltivo/theme-library";

const AboutVideo: SectionComponent = ({ section }) => {
  const [displayVideoIndex, setDisplayVideoIndex] = useState(0);

  return (
    <section id={section.targetId} className="container__max">
      <div className="introduction-two">
        <div className="video-frame" style={{ height: "500px", width: "888.889px" }}>
          <div className="video-frame__poster">
            {section?.data.videos?.map(
              (
                item: {
                  id: string;
                  title: string;
                  src: string;
                  thumbnail: string;
                  playing: boolean;
                  controls: boolean;
                },
                index: number
              ) => {
                if (index !== displayVideoIndex) {
                  return null;
                }
                return (
                  <Video
                    key={index}
                    {...item}
                    dataClass="object"
                    description=""
                    light={true}
                    volume={1}
                    loop={false}
                    muted={false}
                    thumbnail={item.thumbnail}
                    dataPath={`${section.name}/videos/${index}`}
                  />
                );
              }
            )}
          </div>
        </div>

        <div className="introduction-two-content">
          <div className="container">
            {section?.data.videos?.map(
              (
                item: {
                  id: string;
                  title: string;
                  src: string;
                  thumbnail: string;
                  playing: boolean;
                  controls: boolean;
                },
                index: number
              ) => {
                return (
                  <div
                    onClick={() => setDisplayVideoIndex(index)}
                    key={item.id}
                    className={`introduction-two-content__item ${index === displayVideoIndex ? "active" : ""}`}
                    data-src={item.src}>
                    <span>0{index + 1}</span>
                    <i>{item.title}</i>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutVideo;
