import AboutVideo from "./about.video/about.video";
import About from "./about/about";
import Breadcrumb from "./breadcrumb/breadcrumb";
import Contact from "./contact/contact";
import FollowUs from "./follow.us/follow.us";
import Formation from "./formation/formation";
import Formations from "./formations/formations";
import Introduction from "./introduction/introduction";
import IntroductionServices from "./introduction.services/introduction.services";
import Testimonial from "./testimonial/testimonial";
import Services from "./services/services";
import IntroductionVideo from "./introduction.video/introduction.video";

/**
 * @description all the sections created inside the theme.
 */
const sectionComponents: SectionComponents = [
  { name: "introduction", render: Introduction },
  { name: "introduction_video", render: IntroductionVideo },
  { name: "about", render: About },
  { name: "about_video", render: AboutVideo },
  { name: "introduction_services", render: IntroductionServices },
  { name: "testimonial", render: Testimonial },
  { name: "follow_us", render: FollowUs },
  { name: "breadcrumb", render: Breadcrumb },
  { name: "formations", render: Formations },
  { name: "formation", render: Formation },
  { name: "contact", render: Contact },
  { name: "services", render: Services },
];

export default sectionComponents;
