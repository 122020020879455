import { useContext } from "react";
import { Link } from "react-router-dom";
import { WebsiteContext } from "../../data/website.context";
import { ReactComponent as ContentDeco } from "../../assets/images/introduction/IntroductionOne/content-deco.svg";
import imagePlaceholder from "../../helpers/assets/images/image_placeholder.png";
import { Anchor } from "@soltivo/theme-library";

const Services: SectionComponent = ({ section }) => {
  const { website } = useContext(WebsiteContext);
  return (
    <section id={section.id} className="container__max">
      <div className="product-tab -style-1">
        <div className="container">
          <div className="section-title -center" style={{ marginBottom: "1.875em" }}>
            <h2 data-class="text" data-path={`${section.name}/title`}>
              {section?.data.title.value}
            </h2>
            <ContentDeco />
          </div>
          <div className="product-tab__header">
            <p
              style={{
                color: website.colors.dark,
              }}
              data-class="text"
              data-path={`${section.name}/description`}
              className="text-center">
              {section?.data.description.value}
            </p>
          </div>
          <div className="product-tab__content">
            <div className="product-tab__content__wrapper">
              <div className="row mx-n1 mx-lg-n3" data-class="group" data-path={`${section.name}/services`}>
                {(section?.data.services as WebsiteGroup<WebsitePlugin<"booking/service">>).map(
                  (service, serviceIndex: number) => (
                    <div className="col-6 col-md-4 col-lg-3 px-1 px-lg-3" key={serviceIndex}>
                      <div
                        data-class="plugin"
                        data-plugin="booking/service"
                        data-path={`${section.name}/services/${serviceIndex}`}
                        className="product ">
                        <div className="product-thumb">
                          <span className="product-thumb__image">
                            {service.image ? (
                              <img src={service.image} alt="Service image" />
                            ) : (
                              <img src={imagePlaceholder} alt="Service image" />
                            )}
                          </span>
                        </div>
                        <div className="product-content">
                          <Link className="product-name" to={`/services/${service.id}`}>
                            {service.title}
                          </Link>
                          <div className="product-content__footer">
                            <h5 className="product-price--main">Price</h5>
                            <h5 className="product-price--main ml-auto">
                              {(service.price / 100).toLocaleString(undefined, {
                                currency: service.currency,
                                style: "currency",
                              })}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="text-center">
              <Anchor
                {...section?.data.link}
                data-class="link"
                data-path={`${section.name}/link`}
                className="btn -transparent -underline">
                {section?.data.link.value}
              </Anchor>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
